import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
//import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

//ReactGA.initialize("G-QJ3ZQ3CF1L");


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
 /*<React.StrictMode>*/
        <App />
 /*</React.StrictMode >*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
