import React, { Component, useState, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Button from 'react-bootstrap/Button';
import AppSortable from './SortableDnd.js';
import './App.css';
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal';
import ShareOnSocial from "react-share-on-social";
//import ReactGA from "react-ga4";

//ReactGA.initialize("G-QJ3ZQ3CF1L");
//ReactGA.send("pageview");

//import useLocalStorage from './hooks/useLocalStorage.js'

//import CardExample from './ReviewCard' //https://stackoverflow.com/questions/64874103/material-design-bootstrap-mdbreact-is-not-working
//import CardExample from './CardSortable'


//import Sortable from 'sortablejs';



export default class App extends Component {

    static displayName = App.name;
    //static Loading = 1;
    static Stage = {
        "Loading": 1,
        "Playing": 2,
        "Gameover": 3,
        "LoadScores": 4
    };

    //var arrEvent4 = { "eventDate": data.event4Date, "event": data.event4, "id": 4 }

    constructor(props) {
        super(props);
        Object.freeze(App.Stage);
       
        this.state = {
            gamedata: [],
            gameitems: [],
            gameoverdata: [],
            showgameoverdata: "",
            stage: App.Stage.Loading, //loading //playing //gameover //loadscores
            attempts: 0,
            attemptstext: "atempts",
            showgameover: true,
            showhowtoplay: true,
            answers: [],
        };

    }

    componentDidMount() {
    //    this.populateTodaysGameData();

        let today = new Date();
        //today.setDate(today.getDate() - 1) //uncomment to play again
        //let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        let localstoragelastplaydate = localStorage.getItem("lastplaydate");
        
       
        if (localstoragelastplaydate != null && localstoragelastplaydate != undefined) {
            let lastplaydate = new Date(localstoragelastplaydate);
            //alert(today + " " + lastplaydate)
            if (lastplaydate.getFullYear() == today.getFullYear() && lastplaydate.getMonth() == today.getMonth() && lastplaydate.getDate() == today.getDate()) {

                //same day, show gameover
                console.log("show gameover");

                this.setState({ stage: App.Stage.LoadScores });
                let localstorageattempts = localStorage.getItem("attempts");

                //this.setState({ attempts: localstorageattempts });
                this.state.attempts = localstorageattempts;

                //console.log(localstorageattempts);
                //get game data
                this.getTodaysGameData();
                //show gameover
                this.getGameOverData(0); //calling zero will not add to the daily stats

            } else {
                //different day, load the game
                localStorage.setItem("lastplayanswers", "");
                this.getTodaysGameData();
            }
        
        
        } else {
            localStorage.setItem("lastplayanswers", "");
            this.getTodaysGameData();
        }



    }

    //https://www.geeksforgeeks.org/how-to-access-childs-state-in-react/
    handleClick = () => {
        console.log('this is:', this.state.gameitems);
    };

    btnTapped() {
        console.log('tapped');
    }



    getShareSquares() {

        let localstoragelastplayanswers = localStorage.getItem("lastplayanswers").split(',');
        console.log("here:")
        console.log(localstoragelastplayanswers);
      //VERTICAL
      //let sharesquares = "";
      //
      //let rowcount = 0;
      //for (let i = 0; i < this.state.answers.length; i++) {
      //    rowcount = rowcount + 1
      //    if (this.state.answers[i] == 1) {
      //        sharesquares = sharesquares + "&#x1F7E9;"
      //    } else {
      //        sharesquares = sharesquares + "&#x1F7E6;"
      //    }
      //    if (rowcount == 4) {
      //        sharesquares = sharesquares + "<br/>"
      //        rowcount = 0;
      //    }
      //
      //}
      //
      //console.log(sharesquares);
      //return sharesquares;


        let sharesquares = "";

        //let ssgoes = this.state.answers.length / 4;

        sharesquares = sharesquares + "&#x000A";

        let i = 0; //answers
        let j = 1; //go

        ///// UNCOMMENT FOR BLANK SQUARE HEADERS
        /////do {
        /////
        /////    //sharesquares = sharesquares + j.toString();
        /////  //if (j == 0) {
        /////  //    sharesquares = sharesquares + "&#x31;";
        /////  //} else if (j == 1) {
        /////  //    sharesquares = sharesquares + "&#x32;";
        /////  //} else if (j == 2) {
        /////  //    sharesquares = sharesquares + "&#x33;";
        /////  //} else if (j == 3) {
        /////  //    sharesquares = sharesquares + "&#x34;";
        /////  //} else if (j == 4) {
        /////  //    sharesquares = sharesquares + "&#x35;";
        /////  //} else if (j == 5) {
        /////  //    sharesquares = sharesquares + "&#x36;";
        /////  //}
        /////    sharesquares = sharesquares + "&#x1F532;";
        /////
        /////    j = j + 1;
        /////    i = i + 4;
        /////
        /////}
        /////while (i < localstoragelastplayanswers.length);
        /////
        /////sharesquares = sharesquares + "&#x000A";


        j = 0;
        i = 0;
        do {

            if (localstoragelastplayanswers[i] == 1) {
                sharesquares = sharesquares + "&#x1F7E9;";
            } else {
                sharesquares = sharesquares + "&#x1F7E6;";
            }

            i = i + 4;

            j = j + 1;
            if (j > 10) {
                break;
            }

        }       
        while (i < localstoragelastplayanswers.length);

        sharesquares = sharesquares + "&#x000A";


        j = 0;
        i = 1
        do {

            if (localstoragelastplayanswers[i] == 1) {
                sharesquares = sharesquares + "&#x1F7E9;";
            } else {;
                sharesquares = sharesquares + "&#x1F7E6;";
            }

            i = i + 4;

            j = j + 1;
            if (j > 10) {
                break;
            }

        }
        while (i < localstoragelastplayanswers.length);

        sharesquares = sharesquares + "&#x000A";


        j = 0;
        i = 2
        do {

            if (localstoragelastplayanswers[i] == 1) {
                sharesquares = sharesquares + "&#x1F7E9;";
            } else {
                sharesquares = sharesquares + "&#x1F7E6;";
            }

            i = i + 4;

            j = j + 1;
            if (j > 10) {
                break;
            }

        }
        while (i < localstoragelastplayanswers.length);

        sharesquares = sharesquares + "&#x000A";


        j = 0;
        i = 3
        do {

            if (localstoragelastplayanswers[i] == 1) {
                sharesquares = sharesquares + "&#x1F7E9;";
            } else {
                sharesquares = sharesquares + "&#x1F7E6;";
            }

            i = i + 4;

            j = j + 1;
            if (j > 10) {
                break;
            }

        }
        while (i < localstoragelastplayanswers.length);

        //sharesquares = sharesquares + "&#x000A";



        console.log(sharesquares);
        return sharesquares;

    }




    onClickCheckGame(p_score, p_attempt, p_answers) {
        console.log(p_attempt);
        this.setState({ attempts: p_attempt });



        //save the answers
        console.log(p_answers);
        this.setState({ answers: this.state.answers.concat(p_answers) });
        //console.log(this.state.answers);
        //save the answers



        //console.log("state", this.state)
        if (p_score > 0) {

            //save final answers to local storage
            localStorage.setItem("lastplayanswers", this.state.answers.concat(p_answers)); 

            // change stage to gameover
            this.setState({ stage: App.Stage.Gameover });

            // store gameover locally
            localStorage.setItem("attempts", p_attempt);
            let today = new Date();
            //let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            localStorage.setItem("lastplaydate", today.toString());

            // show gameover
            this.getGameOverData(p_attempt);
            //this.getShareSquares();
        } else {
            this.setState({ stage: App.Stage.Playing });
        }
 
    }



    handleHowToPlayClose() {
        this.setState({ showhowtoplay: false });   
    }

    handleHowToPlayShow() {
        this.setState({ showhowtoplay: true });
    }

    handleGameoverClose() {
        this.setState({ showgameover: false });
    }

    handleGameoverShow() {
        this.setState({ showgameover: true });
    }

    handleShareMyScore() {

        //const el = this.shareSquares;
        //el.select();
        //document.execCommand("copy");

        // Send a custom event
       //ReactGA.event({
       //    category: "share",
       //    action: "share_button"
       //});

       //// Send a custom event
       //window.gtag("event", "share", {
       //    "event_category": "share_button",
       //});

        if (this.state.attempts == 1) {
            navigator.share({
                title: 'QIZU',
                text: "Can you equal my QIZU score today of " + this.state.attempts + " " + (this.state.attempts == 1 ? "attempt" : "attempts") + "?" + this.shareSquares.innerHTML,
                url: 'https://qizu.co.uk',
                //file: 'https://qizu.co.uk/qizu.png',
            })
        } else {
            navigator.share({
                title: 'QIZU',
                text: "Can you beat my QIZU score today of " + this.state.attempts + " " + (this.state.attempts == 1 ? "attempt" : "attempts") + "?" + this.shareSquares.innerHTML,
                url: 'https://qizu.co.uk',
                //file: 'https://qizu.co.uk/qizu.png',
            })
        }

    }


    render() {


      //let contents = this.state.loading
      //    ? <p><em>Loading... Please refresh once the ASP.NET backend has started. See <a href="https://aka.ms/jspsintegrationreact">https://aka.ms/jspsintegrationreact</a> for more details.</em></p>
      //   : App.renderGame(this.state.gamedata, this.state.gameitems);


        const gameitemsSorted = [].concat(this.state.gameitems).sort((a, b) => a.eventDate > b.eventDate ? 1 : -1) //make a copy and sort the game items into the CORRECT order


        if (this.state.stage == App.Stage.Loading) {
            return (
                <div className="d-flex align-items-center justify-content-center loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );

        } else if (this.state.stage == App.Stage.Gameover) {

           //ReactGA.event({
           //    category: "game",
           //    action: "gameover"
           //});

            // Send a custom event
           //window.gtag("event", "game", {
           //    "event_category": "gameover",
           //});

            return (

                <div id="div-gameover">


                    <div className="d-flex flex-column p-3 game-max-width">

                        {/*<h3 className="text-center text-logo"><span className="coe-blue-color">Ch</span><span className="coe-orange-color">Egg</span><span className="coe-grey-color">le</span></h3>*/}
                        <img src="qizu.png" alt="QIZU" className="qizu-logo" />
                        {/*<p className="text-center">The daily <span className='italic'><strong>What came first, second...?</strong></span> brainteaser</p>*/}

                        <h4 className="text-center coe-blue-color mb-3">Today's theme: <strong>{this.state.gamedata.eventCategory}</strong></h4>
                        {/* <p className="text-center">{this.state.gamedata.eventIntro}</p> */}


                        {gameitemsSorted.map(function (gameitem) {

                            return (<Card className="bg-success gap-3 mb-2 rounded-0 shadow-sm" key={gameitem.id}>
                                <Card.Body>
                                    <Card.Text className="text-white text-center">
                                        {gameitem.event}<br />{gameitem.eventDate}
                                    </Card.Text>
                                </Card.Body>
                            </Card>)

                            })
                        }

                        <div className="pt-2 pb-2 d-flex flex-row justify-content-between chicken-book">
                            <div className="w-75">
                                <p className="text-left"><a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank">Click on the images to buy the books on which QIZU is based. Treat yourself or the quiz/trivia fan in your life!</a></p>
                                <p className="text-left">Visit tomorrow for the next challenge.</p>
                            </div>
                            <div className="book-cover ms-2">
                                <a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank"><img src="chicken-or-egg-book-cover.jpg" alt="Chicken Or Egg Book Cover" className="mw-100" /></a>
                            </div>
                            <div className="book-cover ms-2">
                                <a href='https://www.amazon.co.uk/First-Born-fancy-tickling-collection-bamboozlers/dp/1739132122/ ' target="_blank"><img src="first-born-book-cover.jpg" alt="First Born Book Cover" className="mw-100" /></a>
                            </div>
                        </div>


                        <Button id="btn-compareyourscore" className="coe-orange-background-color w-100 rounded-0" onClick={this.handleGameoverShow.bind(this)}>
                            Compare Your Score
                        </Button>


                    </div>




                    {/*
                    <Button variant="primary" onClick={this.handleShow.bind(this)}>
                        Launch static backdrop modal
                    </Button>
                    */}

                    {/*https://react-bootstrap.github.io/components/modal/*/}
                    <Modal
                        show={this.state.showgameover}
                        onHide={this.handleGameoverClose.bind(this)}
                        backdrop="static"
                        keyboard={false}
                        fullscreen={true}
                    >
                        {/* 
                        <Modal.Header closeButton>
                            <Modal.Title>&nbsp;</Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body className="p-0">
                            <div className="d-flex flex-column p-3 game-max-width">

                            {/*<h3 className="text-center text-logo"><span className="coe-blue-color">Ch</span><span className="coe-orange-color">Egg</span><span className="coe-grey-color">le</span></h3>*/}
                                <img src="qizu.png" alt="QIZU" className="qizu-logo" />
                            {/*<p className="text-center">The daily <span className='italic'><strong>What came first, second...?</strong></span> brainteaser</p>*/}
                            <h4 className="text-center coe-blue-color">Today's theme: <strong>{this.state.gamedata.eventCategory}</strong></h4>

                                <h3 className="coe-orange-color text-center pt-2">SCORE COMPARISON</h3>

                                <h4 className="coe-orange-color text-center">Your score today: {this.state.attempts} {this.state.attemptstext}</h4>


                                <div className="scores pt-2 pb-2">

                                <div className="score">
                                    <div className="scoreTitle">1</div>
                                        <div className={"scoreBar" + (this.state.attempts == 1 ? " scoreBarSelected" : "")}><div className="bar" style={{ width: this.state.showgameoverdata.score1bar + '%' }}></div></div>
                                    <div className="scorePercentage">{this.state.showgameoverdata.score1bar}%</div>
                                </div>

                                <div className="score">
                                    <div className="scoreTitle">2</div>
                                        <div className={"scoreBar" + (this.state.attempts == 2 ? " scoreBarSelected" : "")}><div className="bar" style={{ width: this.state.showgameoverdata.score2bar + '%' }}></div></div>
                                    <div className="scorePercentage">{this.state.showgameoverdata.score2bar}%</div>
                                </div>

                                <div className="score">
                                    <div className="scoreTitle">3</div>
                                        <div className={"scoreBar" + (this.state.attempts == 3 ? " scoreBarSelected" : "")}><div className="bar" style={{ width: this.state.showgameoverdata.score3bar + '%' }}></div></div>
                                    <div className="scorePercentage">{this.state.showgameoverdata.score3bar}%</div>
                                </div>

                                <div className="score">
                                    <div className="scoreTitle">4</div>
                                        <div className={"scoreBar" + (this.state.attempts == 4 ? " scoreBarSelected" : "")}><div className="bar" style={{ width: this.state.showgameoverdata.score4bar + '%' }}></div></div>
                                    <div className="scorePercentage">{this.state.showgameoverdata.score4bar}%</div>
                                </div>

                                <div className="score">
                                    <div className="scoreTitle">5</div>
                                        <div className={"scoreBar" + (this.state.attempts == 5 ? " scoreBarSelected" : "")}><div className="bar" style={{ width: this.state.showgameoverdata.score5bar + '%' }}></div></div>
                                    <div className="scorePercentage">{this.state.showgameoverdata.score5bar}%</div>
                                </div>

                                <div className="score">
                                    <div className="scoreTitle">6+</div>
                                        <div className={"scoreBar" + (this.state.attempts > 5 ? " scoreBarSelected" : "")}><div className="bar" style={{ width: this.state.showgameoverdata.scoreotherbar + '%' }}></div></div>
                                    <div className="scorePercentage">{this.state.showgameoverdata.scoreotherbar}%</div>
                                </div>

                            </div>
                                
                           

                                <div className="pt-2 pb-2 d-flex flex-row justify-content-between chicken-book">
                                    <div className="w-75">
                                        <p className="text-left"><a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank">Click on the images to buy the books on which QIZU is based. Treat yourself or the quiz/trivia fan in your life!</a></p>
                                        <p className="text-left">Visit tomorrow for the next challenge.</p>
                                    </div>
                                    <div className="book-cover ms-2">
                                        <a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank"><img src="chicken-or-egg-book-cover.jpg" alt="Chicken Or Egg Book Cover" className="mw-100" /></a>
                                    </div>
                                    <div className="book-cover ms-2">
                                        <a href='https://www.amazon.co.uk/First-Born-fancy-tickling-collection-bamboozlers/dp/1739132122/ ' target="_blank"><img src="first-born-book-cover.jpg" alt="First Born Book Cover" className="mw-100" /></a>
                                    </div>
                                </div>
                            

                            <div ref={(sharesquares) => this.shareSquares = sharesquares} id="sharetext" className="w-auto align-self-center fw-bold d-none" dangerouslySetInnerHTML={{ __html: this.getShareSquares() }}></div>
                            <div className="pt-2 pb-2 d-flex flex-row justify-content-between chicken-book">
                                <Button id="btn-seetheanswers" className="coe-orange-background-color w-50 clearfix rounded-0" onClick={this.handleGameoverClose.bind(this)}>
                                    See The Answers
                                </Button>


                                <Button id="btn-sharemyscore" className="coe-green-background-color w-50 clearfix rounded-0" onClick={this.handleShareMyScore.bind(this)}>
                                        Share My Score
                                </Button>
                                    {/* 
                                    <ShareOnSocial
                                    textToShare={"Can you beat my QIZU score today of " + this.state.attempts + " " + (this.state.attempts = 1 ? "attempt" : "attempts") + "..."}
                                    link="https://qizu.co.uk"
                                    linkTitle="QIZU"
                                    linkMetaDesc="The daily What came first, second...? brainteaser"
                                    linkFavicon="https://qizu.co.uk/logo512.png"
                                    noReferer
                                    >

                                    <Button className="coe-green-background-color w-50 clearfix rounded-0">Share My Score</Button>
                                    </ShareOnSocial>
                                    */}
                            </div>

                            </div>
                        </Modal.Body>

                        {/*
                        <Modal.Footer>
                            <Button className="coe-orange-background-color" onClick={this.handleGameoverClose.bind(this)}>
                                See The Answers
                            </Button>
                        </Modal.Footer>
                        */}

                    </Modal>


                </div>


            );

        } else { //this.state.stage == App.Stage.Playing

           //ReactGA.event({
           //    category: "game",
           //    action: "play"
           //});
            // Send a custom event
           //window.gtag("event", "game", {
           //    "event_category": "play",
           //});

            return (
                <div id="div-playing">


                    <div className="d-flex flex-column p-3 game-max-width">

                        {/*<h3 className="text-center text-logo"><span className="coe-blue-color">Ch</span><span className="coe-orange-color">Egg</span><span className="coe-grey-color">le</span></h3>*/}
                        <img src="qizu.png" alt="QIZU" className="qizu-logo" />
                        {/*<p className="text-center">The daily <span className='italic'><strong>What came first, second...?</strong></span> brainteaser</p>*/}


                        <div className="d-flex flex-column text-center">

                            <h4 className="coe-blue-color">Today's theme: <strong>{this.state.gamedata.eventCategory}</strong></h4>
                            <p>{this.state.gamedata.eventIntro}</p>
                            {/* 
                            <ul>
                            {gameitems.map(function (gameitem) {

                               return(<li key={gameitem.id}>{gameitem.event}</li> )

                            })} 
                            </ul> 
                            */}

                            {/*https://stackoverflow.com/questions/43284394/react-child-calling-parent-method-cant-access-state*/}
                            <AppSortable gameitems={this.state.gameitems} onClickCheckGame={this.onClickCheckGame.bind(this)} />



                        </div>

                        {
                            //   <Button variant="primary" onClick={this.handleClick}>
                            //       parent
                            //  </Button>
                        }


                        <div className="pt-2 pb-2 d-flex flex-row justify-content-between chicken-book">
                            <div className="w-75">
                                <p className="text-left"><a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank">Click on the images to buy the books on which QIZU is based. Treat yourself or the quiz/trivia fan in your life!</a></p>
                                <p className="text-left">Visit tomorrow for the next challenge.</p>
                            </div>
                            <div className="book-cover ms-2">
                                <a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank"><img src="chicken-or-egg-book-cover.jpg" alt="Chicken Or Egg Book Cover" className="mw-100" /></a>
                            </div>
                            <div className="book-cover ms-2">
                                <a href='https://www.amazon.co.uk/First-Born-fancy-tickling-collection-bamboozlers/dp/1739132122/ ' target="_blank"><img src="first-born-book-cover.jpg" alt="First Born Book Cover" className="mw-100" /></a>
                            </div>
                        </div>

                    </div>



                    <Modal
                        show={this.state.showhowtoplay}
                        onHide={this.handleHowToPlayClose.bind(this)}
                        backdrop="static"
                        keyboard={false}
                        fullscreen={true}
                    >
                        {/* 
                        <Modal.Header closeButton>
                            <Modal.Title>&nbsp;</Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body className="p-0">
                            <div className="d-flex flex-column p-3 game-max-width">
                            {/*<h3 className="text-center text-logo"><span className="coe-blue-color">Ch</span><span className="coe-orange-color">Egg</span><span className="coe-grey-color">le</span></h3>*/}
                            <img src="qizu.png" alt="QIZU" className="qizu-logo" />
                            {/*<p className="text-center">The daily <span className='italic'><strong>What came first, second...?</strong></span> brainteaser</p>*/}
                            <h3 className="text-center coe-blue-color">How To Play</h3>


                            <div className="mt-2 mb-2">
                                <ol className="text-left">

                                        <li>Slide the items into the order requested and click <span className="coe-orange-background-color">&nbsp;Submit&nbsp;</span></li>
                                        <br />
                                        <li>After three attempts, QIZU will give you a nudge by highlighting correct answers in <span className="coe-green-background-color">&nbsp;green&nbsp;</span></li>
                                        <br />
                                        <li>Share your score</li>

                                </ol>
                            </div>


                                <div className="pt-2 pb-2 d-flex flex-row justify-content-between chicken-book">
                                    <div className="w-75">
                                        <p className="text-left"><a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank">If you enjoy the challenge, you can <u>buy the books</u> on which QIZU is based by clicking on the book images.</a></p>
                                    </div>
                                    <div className="book-cover ms-2">
                                        <a href='https://www.amazon.co.uk/Chicken-Egg-befuddling-Second-brainteasers/dp/1739132106/' target="_blank"><img src="chicken-or-egg-book-cover.jpg" alt="Chicken Or Egg Book Cover" className="mw-100" /></a>
                                    </div>
                                    <div className="book-cover ms-2">
                                        <a href='https://www.amazon.co.uk/First-Born-fancy-tickling-collection-bamboozlers/dp/1739132122/ ' target="_blank"><img src="first-born-book-cover.jpg" alt="First Born Book Cover" className="mw-100" /></a>
                                    </div>
                                </div>

                                <Button className="coe-orange-background-color w-100 mt-3 rounded-0" onClick={this.handleHowToPlayClose.bind(this)}>
                                    Play Now!
                                </Button>

                            </div>
                        </Modal.Body>

                        {/* 
                        <Modal.Footer>
                            <Button className="coe-orange-background-color" onClick={this.handleHowToPlayClose.bind(this)}>
                                Play Now!
                            </Button>
                        </Modal.Footer>
                        */}

                    </Modal>








                </div>
            );


        }

    }





    async getTodaysGameData() {
        console.log("getGameData");
        
        //const response = await fetch('https://localhost:7223/api/tblevents/today', { crossDomain: true, headers: { 'Content-Type': 'application/json' } });
        const response = await fetch('https://api.qizu.co.uk/api/tblevents/today', { crossDomain: true, headers: { 'Content-Type': 'application/json' } });
        const data = await response.json();

        var arrEvent1 = { "eventDate": data.event1Date, "event": data.event1, "id": 1 }
        var arrEvent2 = { "eventDate": data.event2Date, "event": data.event2, "id": 2 }
        var arrEvent3 = { "eventDate": data.event3Date, "event": data.event3, "id": 3 }
        var arrEvent4 = { "eventDate": data.event4Date, "event": data.event4, "id": 4 }

        //this.setState({ gamedata: data, gameitems: [arrEvent1, arrEvent2, arrEvent3, arrEvent4], loading: false, attempts: 0});
        if (this.state.stage == App.Stage.Loading) {
            this.setState({ gamedata: data, gameitems: [arrEvent1, arrEvent2, arrEvent3, arrEvent4], stage: App.Stage.Playing });
        } else {
            this.setState({ gamedata: data, gameitems: [arrEvent1, arrEvent2, arrEvent3, arrEvent4] });
        }

    }




    async getGameOverData(attempt) {

        this.setState({ stage: App.Stage.Loading });

        if (attempt > 0) { 

            if (attempt > 1) {
                this.state.attemptstext = "attempts"
            } else {
                this.state.attemptstext = "attempt"
            }

        } else {

            if (this.state.attempts > 1) {
                this.state.attemptstext = "attempts"
            } else {
                this.state.attemptstext = "attempt"
            }

        }


        //this.state.showgameoverdata = "<Fragment><div className='d-flex align-items-center justify-content-center loader'><div className='spinner-border' role='status'><span className='sr-only'>Loading...</span></div></div></Fragment>"

        console.log("getGameOverData");

        const response = await fetch('https://api.qizu.co.uk/api/tblgameover/' + attempt, { crossDomain: true, headers: { 'Content-Type': 'application/json' } });
        const data = await response.json();

        //this.setState({ "statsTotalPlays": data.statsTotalPlays, "statsScore1": data.statsScore1, "statsScore2": data.statsScore2, "statsScore3": data.statsScore3, "statsScore4": data.statsScore4, "statsScore5": data.statsScore5, "statsScoreOther": data.statsScoreOther });

        console.log(data.statsTotalPlays)
        console.log(data.statsScore1)

        let getgameoverdata = ({
            "score1bar": Math.round((100 / data.statsTotalPlays) * data.statsScore1),
            "score2bar": Math.round((100 / data.statsTotalPlays) * data.statsScore2),
            "score3bar": Math.round((100 / data.statsTotalPlays) * data.statsScore3),
            "score4bar": Math.round((100 / data.statsTotalPlays) * data.statsScore4),
            "score5bar": Math.round((100 / data.statsTotalPlays) * data.statsScore5),
            "scoreotherbar": Math.round((100 / data.statsTotalPlays) * data.statsScoreOther)
        });

        this.setState({ showgameoverdata: getgameoverdata });

        //this.state.showgameoverdata = ({
        //    "score1bar": Math.floor((100 / data.statsTotalPlays) * data.statsScore1),
        //    "score2bar": Math.floor((100 / data.statsTotalPlays) * data.statsScore2),
        //    "score3bar": Math.floor((100 / data.statsTotalPlays) * data.statsScore3),
        //    "score4bar": Math.floor((100 / data.statsTotalPlays) * data.statsScore4),
        //    "score5bar": Math.floor((100 / data.statsTotalPlays) * data.statsScore5),
        //    "scoreotherbar": Math.floor((100 / data.statsTotalPlays) * data.statsScoreOther)
        //    });

        this.setState({ stage: App.Stage.Gameover });

    }




}
