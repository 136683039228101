import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Card from 'react-bootstrap/Card'


export function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };



    if (props.correct == true) { /* highlight a correct answer */

        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

                <Card className="coe-green-background-color gap-3 mb-2 rounded-0 shadow-sm">
                    <Card.Body>
                        <Card.Text className="text-white">
                            {props.event} {/*{props.order} {props.date} ({props.id})*/}
                        </Card.Text>
                    </Card.Body>
                </Card>

            </div>
        );

    } else if (props.wrong == true) { /* highlight a wrong answer - cuurently disabled */

        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

                <Card className="coe-blue-background-color gap-3 mb-2 rounded-0 shadow-sm">
                    <Card.Body>
                        <Card.Text className="text-white">
                            {props.event} {/*{props.order} {props.date} ({props.id})*/}
                        </Card.Text>
                    </Card.Body>
                </Card>

            </div>
        );

    } else { /* no highlight answer */

        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>

                <Card className="coe-blue-background-color gap-3 mb-2 rounded-0 shadow-sm">
                    <Card.Body>
                        <Card.Text className="text-white">
                            {props.event} {/*{props.order} {props.date} ({props.id})*/}
                        </Card.Text>
                    </Card.Body>
                </Card>

            </div>
        );

    }





}