import React, { useState, Component, Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';


import { SortableItem } from './SortableDndItem';



export default function SortableDnd(props, _ref) {

    const [shake, setShake] = useState(false);
    const [gameitems, setGameItems] = useState(props.gameitems);
    const [attempts, setAttempts] = useState(0);
    const [showcorrect, setShowCorrect] = useState(false);
    const [gameitemscorrect, setGameItemsCorrect] = useState([]);
    const [attemptsMsg, setAttemptsMsg] = useState("<Fragment><strong class='coe-orange-color'>Have A Go!<strong><br/>0 Attempts</Fragment>");
    const [showgameovermsg, setShowGameoverMsg] = useState(false);
    const [gameoverMsg, setGameoverMsg] = useState("Well done!");


    //const [items, setItems] = useState([1, 2, 3, 4]);

    const sensors = useSensors(
        useSensor(TouchSensor, {}),
        useSensor(MouseSensor, {}),
       // useSensor(KeyboardSensor, {
       //     coordinateGetter: sortableKeyboardCoordinates,
       // })
    );

    //console.log(gameitems)

   //const dataorder = [...props.gameitems]; //this copies the array https://stackoverflow.com/questions/9592740/how-can-you-sort-an-array-without-mutating-the-original-array
   //dataorder.sort(function (x, y) {
   //    return x.eventDate - y.eventDate;
   //});
    const dataorder = [].concat(gameitems).sort((a, b) => a.eventDate > b.eventDate ? 1 : -1) //make a copy and sort the game items into the CORRECT order


    //let showcorrect = false;

    return (
        <div className="d-flex flex-column">


        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}>
                
            <SortableContext
                items={gameitems}
                disabled={showgameovermsg}
                strategy={verticalListSortingStrategy}>

                    {/* 
               //{gameitems.map(function (gameitem, i) {
               //
               //    let order = dataorder.findIndex(obj => obj.id === gameitem.id);
               //
               //    //return (<SortableItem key={gameitem.id} id={gameitem.id} event={gameitem.event} date={gameitem.eventDate} myorder={order} />)
               //
               //    if (order == i && attempts > 3 && showcorrect == true) {
               //        return (<SortableItem key={gameitem.id} id={gameitem.id} event={gameitem.event} date={gameitem.eventDate} order={order} correct={true} />)
               //    } else {
               //        return (<SortableItem key={gameitem.id} id={gameitem.id} event={gameitem.event} date={gameitem.eventDate} order={order} correct={false} />)
               //    }
               //
               //})}
               */}

                {gameitems.map(function (gameitem, i) {

                    if (gameitemscorrect.includes(gameitem.id) && showcorrect == true) {
                        return (<SortableItem key={gameitem.id} id={gameitem.id} event={gameitem.event} date={gameitem.eventDate} wrong={false} correct={true} />)
                    } else if (showcorrect == true) {
                        return (<SortableItem key={gameitem.id} id={gameitem.id} event={gameitem.event} date={gameitem.eventDate} wrong={true} correct={false} />)
                    } else {
                        return (<SortableItem key={gameitem.id} id={gameitem.id} event={gameitem.event} date={gameitem.eventDate} wrong={false} correct={false} />)
                    }


                })}
           

                    {/*
                    {items.map(id =>
                        <SortableItem key={id} id={id} />
                    )}
                    */}

            </SortableContext>
            </DndContext>


            <div className="d-flex flex-row align-self-center w-100 pt-2 pb-2 mt-2">

                <Button variant="primary" className="w-50 coe-orange-background-color rounded-0" onClick={handleClick}>SUBMIT</Button>

                <div className={"w-50 align-self-center fw-bold " + (shake ? `shake` : null)} dangerouslySetInnerHTML={{ __html: attemptsMsg }}></div>

            </div>



            <Modal
                show={showgameovermsg}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h5 className="text-center mb-0">{gameoverMsg}</h5>
                </Modal.Body>
            </Modal>





        </div>
    );

    function handleClick() {
        console.log('this is:', gameitems);
        //props.onClickCheckGame(0);


        //https://erikmartinjordan.com/add-animation-onClick-react
        // Button begins to shake
        setShake(true);
        // Buttons stops to shake after 2 seconds
        setTimeout(() => setShake(false), 500);


        //setAttemptsMsg("<strong>Incorrect - Try Again<strong><br/>" + attempts + 1 + " Attempts");


        //increment game attempts
        setAttempts(attempts + 1);

        //get the current selected answers
        var date1 = gameitems[0].eventDate;
        var date2 = gameitems[1].eventDate;
        var date3 = gameitems[2].eventDate;
        var date4 = gameitems[3].eventDate;

        //console.log(date1, date2, date3, date4)


        //// check which are correct answers
        let answers = [];

        setGameItemsCorrect([]) //reset correct answers

        gameitems.map(function (gameitem, i) {

            let order = dataorder.findIndex(obj => obj.id === gameitem.id);

            if (order == i) {
                //correct = true
                answers.push(1);
                setGameItemsCorrect(current => [...current, gameitem.id]);
            } else {
                //correct = false
                answers.push(0);
            }

        })

        //console.log(answers)
        //// end check which are correct answers




        //have we won the game
        //const gameitemsSorted = [].concat(gameitems).sort((a, b) => a.eventDate > b.eventDate ? 1 : -1) //make a copy and sort the game items into the CORRECT order

        console.log("//")
        console.log(dataorder[0]["eventDate"])
        console.log(gameitems[0]["eventDate"])
        console.log("//")

        if (dataorder[0]["eventDate"] == gameitems[0]["eventDate"] && dataorder[1]["eventDate"] == gameitems[1]["eventDate"] && dataorder[2]["eventDate"] == gameitems[2]["eventDate"] && dataorder[3]["eventDate"] == gameitems[3]["eventDate"]) {
            //***** WIN WIN WIN WIN *****//
            setAttemptsMsg("<Fragment><strong class='coe-green-color'>Correct!<strong><br/>" + (attempts + 1) + " " + ((attempts + 1) == 1 ? "attempt" : "attempts") + "</Fragment>");

            if (attempts + 1 == 1) {
                setGameoverMsg("You aced it!")
            } else if (attempts + 1 == 2) {
                setGameoverMsg("Brilliant!")
            } else if (attempts + 1 == 3) {
                setGameoverMsg("Excellent!")
            } else if (attempts + 1 == 4) {
                setGameoverMsg("Well Done!")
            } else if (attempts + 1 == 5) {
                setGameoverMsg("Very Good!")
            } else if (attempts + 1 == 6) {
                setGameoverMsg("Great!")
            } else if (attempts + 1 > 6) {
                setGameoverMsg("Good Effort!")
            }

            setShowCorrect(true); //set answers to green
            setShowGameoverMsg(true); //disable the DnD and show the popup Modal

            setTimeout(function () {
                props.onClickCheckGame(1, attempts + 1, answers); //win
            }, 2000);


        } else {
            //***** TRY AGAIN TRY AGAIN TRY AGAIN *****/
            setAttemptsMsg("<Fragment><strong class='coe-orange-color'>Incorrect - Try Again<strong><br/>" + (attempts + 1) + " " + ((attempts + 1) == 1 ? "attempt" : "attempts") + "</Fragment>");

            if (attempts + 1 > 3) { //show correct answers as green after 3 goes 
                setShowCorrect(true);
            }

            props.onClickCheckGame(0, attempts + 1, answers); //lose

        }



       //for (var i = 0; i < gameitems.length; i++) {
       //    console.log(gameitems[i]);
       //    //Do something
       //}

    };

    function handleDragEnd(event) {
        const { active, over } = event;

        setShowCorrect(false);

        if (active.id !== over.id) {
           //setItems((items) => {
           //    const oldIndex = items.indexOf(active.id);
           //    const newIndex = items.indexOf(over.id);
           //
           //    return arrayMove(items, oldIndex, newIndex);
           //});

            //console.log(active.id)
            //console.log(over.id)

            setGameItems((gameitems) => {
                //console.log("items")
                //console.log(gameitems)
                //const oldIndex = gameitems.indexOf(active.id);
                //const newIndex = gameitems.indexOf(over.id);

                //https://stackoverflow.com/questions/52641492/how-to-get-the-index-of-selected-element-in-react-native-array
                const oldIndex = gameitems.findIndex(obj => obj.id === active.id);
                const newIndex = gameitems.findIndex(obj => obj.id === over.id);

                //console.log(oldIndex)
                //console.log(newIndex)

                return arrayMove(gameitems, oldIndex, newIndex);
            });
        }
    }



}
